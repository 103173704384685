import React from "react";

const Badge = (props) => {
  let bgBadge = "bg-emerald-100 text-emerald-600";
  if (props.tipe === "danger") {
    bgBadge = "bg-rose-100 text-rose-600";
  } else if (props.tipe === "warning") {
    bgBadge = "bg-orange-100 text-orange-600";
  } else if (props.tipe === "info") {
    bgBadge = "bg-blue-100 text-blue-600";
  } else if (props.tipe === "dark") {
    bgBadge = "bg-lime-100 text-lime-600";
  } else if (props.tipe === "slate") {
    bgBadge = "bg-slate-100 text-slate-600";
  } else if (props.tipe === "yellow") {
    bgBadge = "bg-yellow-100 text-yellow-600";
  }

  return (
    <span
      className={`pt-1 pl-2 pr-2 pb-1 font-sans text-[10px] rounded-md ${bgBadge}`}
    >
      {props.text}
    </span>
  );
};

export default Badge;
