import Root from "./Root";
import Login from "../pages/Login";

// home
import HomeDashboard from "../pages/HomeDashboard";
import TableDashboardPolda from "../pages/HomeDashboard/TableDashboardPolda";
import TableDashboardSamsat from "../pages/HomeDashboard/TableDashboardSamsat";

import Analytics from "../pages/Analytics";

import User from "../pages/User";
import TableUser from "../pages/User/Table";
import FormAddUser from "../pages/User/FormAdd";
import FormEditUser from "../pages/User/FormEdit";
import FormPassword from "../pages/User/FormPassword";

import Polda from "../pages/Polda";
import TablePolda from "../pages/Polda/Table";
import FormAddPolda from "../pages/Polda/FormAdd";
import FormEditPolda from "../pages/Polda/FormEdit";

import Samsat from "../pages/Samsat";
import TableSamsat from "../pages/Samsat/Table";
import FormAddSamsat from "../pages/Samsat/FormAdd";
import FormEditSamsat from "../pages/Samsat/FormEdit";

import BbmKendaraan from "../pages/BbmKendaraan";
import TableBbmKendaraan from "../pages/BbmKendaraan/Table";
import FormAddBbmKendaraan from "../pages/BbmKendaraan/FormAdd";
import FormEditBbmKendaraan from "../pages/BbmKendaraan/FormEdit";

import JenisKendaraan from "../pages/JenisKendaraan";
import TableJenisKendaraan from "../pages/JenisKendaraan/Table";
import FormAddJenisKendaraan from "../pages/JenisKendaraan/FormAdd";
import FormEditJenisKendaraan from "../pages/JenisKendaraan/FormEdit";

import ModelKendaraan from "../pages/ModelKendaraan";
import TableModelKendaraan from "../pages/ModelKendaraan/Table";
import FormAddModelKendaraan from "../pages/ModelKendaraan/FormAdd";
import FormEditModelKendaraan from "../pages/ModelKendaraan/FormEdit";

import WarnaTnkb from "../pages/WarnaTnkb";
import TableWarnaTnkb from "../pages/WarnaTnkb/Table";
import FormAddWarnaTnkb from "../pages/WarnaTnkb/FormAdd";
import FormEditWarnaTnkb from "../pages/WarnaTnkb/FormEdit";

import KetPendaftaran from "../pages/KetPendafataran";
import TableKetPendaftaran from "../pages/KetPendafataran/Table";
import FormAddKetPendaftaran from "../pages/KetPendafataran/FormAdd";
import FormEditKetPendaftaran from "../pages/KetPendafataran/FormEdit";

import GenerateToken from "../pages/GenerateToken";
import TableGenerateToken from "../pages/GenerateToken/Table";

import ResetTotalReport from "../pages/ResetTotalReport";
import TableResetTotalReport from "../pages/ResetTotalReport/Table";

import Order from "../pages/Order";
import TableOrder from "../pages/Order/Table";

import Kaworkshop from "../pages/Kaworkshop";
import TableKaworkshop from "../pages/Kaworkshop/Table";
import FormAddKaworkshop from "../pages/Kaworkshop/FormAdd";
import FormEditKaworkshop from "../pages/Kaworkshop/FormEdit";

import ErrorPage from "../pages/ErrorPage";
import { Navigate } from "react-router-dom";

import Renbut from "../pages/Renbut";
import TableRenbut from "../pages/Renbut/Table";
import FormAddRenbut from "../pages/Renbut/FormAdd";
import FormEditRenbut from "../pages/Renbut/FormEdit";

import DashboardRenbut from "../pages/DashboardRenbut";
import TableDashboardRenbut from "../pages/DashboardRenbut/TableDashboardRenbut";

export const Paths = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <HomeDashboard />,
        breadcrumb: "Dashboard",
        children: [
          {
            index: true,
            element: <TableDashboardPolda />,
            breadcrumb: "Dashboard Polda",
          },
          {
            path: "samsat/:poldaKode",
            element: <TableDashboardSamsat />,
            breadcrumb: "Dashboard Samsat",
          },
        ],
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "dashboard-renbut",
        element: <DashboardRenbut />,
        breadcrumb: "Dashboard",
        children: [
          {
            index: true,
            element: <TableDashboardRenbut />,
            breadcrumb: "Dashboard Renbut",
          },
        ],
      },
      {
        path: "user",
        element: <User />,
        breadcrumb: "User",
        children: [
          { index: true, element: <TableUser />, breadcrumb: "User" },
          {
            path: "add",
            element: <FormAddUser />,
            breadcrumb: "Tambah User",
          },
          {
            path: "edit/:id",
            element: <FormEditUser />,
            breadcrumb: "Edit User",
          },
          {
            path: "password/:id",
            element: <FormPassword />,
            breadcrumb: "Edit Password",
          },
        ],
      },
      {
        path: "renbut",
        element: <Renbut />,
        breadcrumb: "Renbut",
        children: [
          {
            index: true,
            element: <TableRenbut />,
            breadcrumb: "Renbut",
          },
          {
            path: "add",
            element: <FormAddRenbut />,
            breadcrumb: "Tambah Renbut",
          },
          {
            path: "edit/:id",
            element: <FormEditRenbut />,
            breadcrumb: "Edit Renbut",
          },
        ],
      },
      {
        path: "polda",
        element: <Polda />,
        breadcrumb: "Polda",
        children: [
          {
            index: true,
            element: <TablePolda />,
            breadcrumb: "Polda",
          },
          {
            path: "add",
            element: <FormAddPolda />,
            breadcrumb: "Tambah Polda",
          },
          {
            path: "edit/:id",
            element: <FormEditPolda />,
            breadcrumb: "Edit Polda",
          },
        ],
      },

      {
        path: "samsat",
        element: <Samsat />,
        breadcrumb: "Samsat",
        children: [
          {
            index: true,
            element: <TableSamsat />,
            breadcrumb: "Samsat",
          },
          {
            path: "add",
            element: <FormAddSamsat />,
            breadcrumb: "Tambah Samsat",
          },
          {
            path: "edit/:id",
            element: <FormEditSamsat />,
            breadcrumb: "Edit Samsat",
          },
        ],
      },
      {
        path: "bbm-kendaraan",
        element: <BbmKendaraan />,
        breadcrumb: "Bbm Kendaraan",
        children: [
          {
            index: true,
            element: <TableBbmKendaraan />,
            breadcrumb: "Bbm Kendaraan",
          },
          {
            path: "add",
            element: <FormAddBbmKendaraan />,
            breadcrumb: "Tambah Bbm Kendaraan",
          },
          {
            path: "edit/:id",
            element: <FormEditBbmKendaraan />,
            breadcrumb: "Edit Bbm Kendaraan",
          },
        ],
      },
      {
        path: "jenis-kendaraan",
        element: <JenisKendaraan />,
        breadcrumb: "Jenis Kendaraan",
        children: [
          {
            index: true,
            element: <TableJenisKendaraan />,
            breadcrumb: "Jenis Kendaraan",
          },
          {
            path: "add",
            element: <FormAddJenisKendaraan />,
            breadcrumb: "Tambah Jenis Kendaraan",
          },
          {
            path: "edit/:id",
            element: <FormEditJenisKendaraan />,
            breadcrumb: "Edit Jenis Kendaraan",
          },
        ],
      },
      {
        path: "model-kendaraan",
        element: <ModelKendaraan />,
        breadcrumb: "Model Kendaraan",
        children: [
          {
            index: true,
            element: <TableModelKendaraan />,
            breadcrumb: "Model Kendaraan",
          },
          {
            path: "add",
            element: <FormAddModelKendaraan />,
            breadcrumb: "Tambah Model Kendaraan",
          },
          {
            path: "edit/:id",
            element: <FormEditModelKendaraan />,
            breadcrumb: "Edit Model Kendaraan",
          },
        ],
      },
      {
        path: "warna-tnkb",
        element: <WarnaTnkb />,
        breadcrumb: "Warna TNKB",
        children: [
          {
            index: true,
            element: <TableWarnaTnkb />,
            breadcrumb: "Warna TNKB",
          },
          {
            path: "add",
            element: <FormAddWarnaTnkb />,
            breadcrumb: "Tambah Warna TNKB",
          },
          {
            path: "edit/:id",
            element: <FormEditWarnaTnkb />,
            breadcrumb: "Edit Warna TNKB",
          },
        ],
      },
      {
        path: "keterangan-pendaftaran",
        element: <KetPendaftaran />,
        breadcrumb: "Keterangan Pendaftaran",
        children: [
          {
            index: true,
            element: <TableKetPendaftaran />,
            breadcrumb: "Keterangan Pendaftaran",
          },
          {
            path: "add",
            element: <FormAddKetPendaftaran />,
            breadcrumb: "Tambah Keterangan Pendaftaran",
          },
          {
            path: "edit/:id",
            element: <FormEditKetPendaftaran />,
            breadcrumb: "Edit Keterangan Pendaftaran",
          },
        ],
      },
      {
        path: "order",
        element: <Order />,
        breadcrumb: "Data Order",
        children: [
          {
            index: true,
            element: <TableOrder />,
            breadcrumb: "Data Order",
          },
        ],
      },
      {
        path: "generate-token",
        element: <GenerateToken />,
        breadcrumb: "Generate Token",
        children: [
          {
            index: true,
            element: <TableGenerateToken />,
            breadcrumb: "Generate Token",
          },
        ],
      },
      {
        path: "reset-total-report",
        element: <ResetTotalReport />,
        breadcrumb: "Reset Total Report",
        children: [
          {
            index: true,
            element: <TableResetTotalReport />,
            breadcrumb: "Reset Total Report",
          },
        ],
      },
      {
        path: "kaworkshop",
        element: <Kaworkshop />,
        breadcrumb: "Kaworkshop",
        children: [
          {
            index: true,
            element: <TableKaworkshop />,
            breadcrumb: "Kaworkshop",
          },
          {
            path: "add",
            element: <FormAddKaworkshop />,
            breadcrumb: "Tambah Kaworkshop",
          },
          {
            path: "edit/:id",
            element: <FormEditKaworkshop />,
            breadcrumb: "Edit Kaworkshop",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
];
