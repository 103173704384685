import React from "react";
import ReactApexChart from "react-apexcharts";
import { sendRequestGet } from "../../../swr";
import { API_URL_OFFICE } from "../../../config";
import useSWR from "swr";
import Loading from "../../Loading";

const BarChartPerbulanR2 = () => {
  const { data: dataPerbulan, isLoading: isLoadingBlnKategori } = useSWR(
    `${API_URL_OFFICE}/report/total-5bulan`,
    sendRequestGet
  );

  if (isLoadingBlnKategori || !dataPerbulan) {
    return <Loading />;
  }

  // get data
  const categories = dataPerbulan.data.dataR2.map((item) => item.blnText);
  const total = dataPerbulan.data.dataR2.map((item) => item.total);
  const totalKonfirm = dataPerbulan.data.dataR2.map(
    (item) => item.totalKonfirm
  );
  const totalHutang = dataPerbulan.data.dataR2.map((item) => item.totalHutang);

  const series = [
    {
      name: "TOTAL",
      data: total,
    },
    {
      name: "KONFIRMASI (OCT)",
      data: totalKonfirm,
    },
    {
      name: "TOTAL HUTANG",
      data: totalHutang,
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
        dataLabels: {
          position: "top", // Position data labels on top of the bars
        },
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Total",
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#000"], // Warna teks untuk visibilitas
      },
      offsetY: -20,
      formatter: function (val) {
        return val;
      },
    },
  };

  return (
    <>
      <div className="w-full bg-white shadow rounded-lg">
        <div className="font-poppins-regular text-lg text-center">
          <p className="font-semibold">DATA ORDER TNKB R2/R3 5 BULAN</p>
        </div>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </>
  );
};

export default BarChartPerbulanR2;
