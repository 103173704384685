import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import jwtDecode from "jwt-decode";
import { Icon } from "@iconify-icon/react";

import { SwitchHari, SwitchBulan } from "../../function/DateFormat";
import FotoProfile from "../../assets/images/people.png";

const Header = (props) => {
  const navigate = useNavigate();

  let date = new Date();
  let tahun = date.getFullYear();
  let bulan = date.getMonth();
  let tanggal = date.getDate();
  let hari = date.getDay();
  let jam = date.getHours();
  let menit = date.getMinutes();
  let detik = date.getSeconds();

  const [tglShow, setTglShow] = useState("");

  // const [fotoProfile, setFotoProfile] = useState("");
  const [namaUser, setNamaUser] = useState("");

  useEffect(() => {
    setTglShow(
      `${SwitchHari(hari)}, ${tanggal} ${SwitchBulan(
        bulan
      )} ${tahun} ${jam}:${menit}:${detik}`
    );

    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const getToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      // setFotoProfile(decoded.fotoUrl);
      setNamaUser(decoded.namaUser);
    }
  };

  return (
    <div className="w-full fixed top-0 z-10 left-0 mb-4 bg-gradient-to-r from-orange-50 to-orange-400 shadow-lg h-[98px] pt-3">
      <div className="ml-64">
        <div className="flex flex-row justify-between items-center px-2">
          <div>
            <div className="absolute inline-flex bg-shape-square flex-shrink-0 w-[98px] h-[98px] top-0 left-[250px] -z-10 opacity-25 bg-no-repeat bg-bottom" />
            <div className="flex flex-row items-center pl-6">
              <Icon
                icon={props.icon}
                style={{ fontSize: "28px", color: "#f97316" }}
              />
              <h1 className="font-sans ml-3 p-0 text-md text-black font-bold">
                {props.titleHead}
              </h1>
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={() => Logout()}
              className="flex flex-row items-center border border-gray-200 bg-white px-4 py-2 rounded-xl shadow-sm hover:shadow-lg"
            >
              <div className="relative w-[27px] h-[27px] rounded-full border border-gray-200 shadow-lg overflow-hidden bg-black">
                <img
                  // src={`${fotoProfile}`}
                  src={FotoProfile}
                  className="absolute block left-0 top-0"
                  alt="foto-profile"
                />
              </div>
              <span className="font-sans text-sm ml-4">{namaUser}</span>
            </button>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center px-2">
          <div className="flex-1 ml-6">
            <Breadcrumbs />
          </div>
          <div>
            <div className="text-right font-sans text-sm text-black">
              {tglShow}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
