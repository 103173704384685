import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const HomeDashboard = () => {
  return (
    <div>
      <Header titleHead="Dashboard" icon="solar:home-smile-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default HomeDashboard;
