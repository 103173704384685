/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { sendRequestGet } from "../../swr";
import { API_URL_MASTER } from "../../config";
import { Icon } from "@iconify-icon/react";
import useSWR from "swr";
import Loading from "../Loading";
import Select2 from "../../components/Form2/Select2";
import PersenRoda from "./PersenRoda";

// import Modal from "../../components/Modal";

const TableDashboardRenbut = () => {
  const navigate = useNavigate();

  const [thn, setThn] = useState(new Date().getFullYear());
  const [dataThn, setDataThn] = useState([]);

  const { data, isLoading } = useSWR(
    `${API_URL_MASTER}/master-report-renbut/polda?tahun=${thn}`,
    sendRequestGet,
    {
      errorRetryInterval: 5000,
    }
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    loadTahun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thn]);

  const loadTahun = () => {
    const dataTh = [];
    for (let index = new Date().getFullYear(); index > 2019; index--) {
      dataTh.push({
        name: `${index}`,
        value: index,
      });
    }

    setDataThn(dataTh);
  };

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
  };

  const totalPersenRenbut = data ? data.totalPersenRenbut : 0;

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="px-4 mt-10">
        <div className="flex gap-2 justify-between">
          <div className="font-bold text-lg text-black mb-2">
            DASHBOARD RENBUT
          </div>
          <div>
            <p className="text-red-500">{data.lastUpdate}</p>
          </div>
        </div>

        <div className="flex gap-2 flex-wrap">
          <div className="flex flex-col bg-white shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Order (ERI)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.total)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-2-bold-duotone"
                  style={{ fontSize: "50px", color: "#e11d48" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Konfirmasi (OCT)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.totalKonfirm)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-bold-duotone"
                  style={{ fontSize: "50px", color: "#e11d48" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Selisih
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.totalSelisih)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:align-bottom-bold-duotone"
                  style={{ fontSize: "50px", color: "#e11d48" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 p-4">
        <div className="w-60">
          <form onSubmit={searchData}>
            <Select2
              label="Tahun"
              name="thn"
              val={thn}
              set={setThn}
              item={dataThn}
            />
          </form>
        </div>
        <div className="bg-white shadow rounded-lg h-auto flex flex-col">
          <span className="font-poppins-regular text-lg font-semibold text-center border-b p-2">
            Persentase Penggunaan Material
          </span>
          <div className="flex items-center justify-center">
            <PersenRoda totalPersenRenbut={totalPersenRenbut} />
          </div>
        </div>
        <div className="bg-white shadow rounded-lg h-auto flex flex-col">
          <span className="font-poppins-regular text-lg font-semibold text-center border-b p-2">
            Jumlah PNBP Diterima
          </span>
          <div className="flex flex-grow items-center justify-center">
            <span className="text-2xl font-bold p-2">
              Rp. {new Intl.NumberFormat("id-ID").format(data.totalPnbp)}
            </span>
          </div>
        </div>
      </div>

      <div className="overflow-auto sm:max-h-[400px] md:max-h-[500px] lg:max-h-[500px] xl:max-h-[500px] 2xl:max-h-[750px] sm:max-w-[576px] md:max-w-[768px] lg:max-w-[992px] xl:max-w-[1170px] 2xl:max-w-[1400px] w-full">
        <table className="table-fixed table w-full font-sans text-sm text-left text-gray-500">
          <thead className="text-gray-600 bg-gray-100">
            <tr>
              <th
                rowSpan="2"
                className="p-2 text-center border-b border-t text-xs sticky left-0 top-0 bg-gray-100 z-30"
                width="50px"
              >
                NO
              </th>
              <th
                rowSpan="2"
                className="px-2 py-3 text-center border-b border-t text-xs sticky left-[50px] top-0 bg-gray-100 z-20"
                width="250px"
              >
                DITLANTAS
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-orange-100 border-b border-t text-xs sticky top-0 z-10"
                width="200px"
              >
                RENBUT JASA EMBOSSING TNKB
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-green-100 border-b border-t text-xs sticky top-0 z-10"
                width="200px"
              >
                RENBUT JASA EMBOSSING TCKB
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-blue-100 border-b border-t text-xs sticky top-0 z-10"
                width="200px"
              >
                EMBOSSING TNKB
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-yellow-100 border-b border-t text-xs sticky top-0 z-10"
                width="200px"
              >
                EMBOSSING TCKB
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-rose-100 border-b border-t text-xs sticky top-0 z-10"
                width="200px"
              >
                EMBOSSING TNKB (HUTANG)
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-lime-100 border-b border-t text-xs sticky top-0 z-10"
                width="200px"
              >
                EMBOSSING TCKB (HUTANG)
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-emerald-100 border-b border-t text-xs sticky top-0 z-10"
                width="250px"
              >
                SISA RENBUT EMBOSSING TNKB
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-pink-100 border-b border-t text-xs sticky top-0 z-10"
                width="150px"
              >
                PERSEN PENGGUNAAN MATERIAL
              </th>
              <th
                colSpan="2"
                className="px-2 py-3 text-center bg-indigo-100 border-b border-t text-xs sticky top-0 z-10"
                width="250px"
              >
                SISA RENBUT EMBOSSING TCKB
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-green-100 border-b border-t text-xs sticky top-0 z-10"
                width="400px"
              >
                NILAI PNBP TNKB
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-violet-100 border-b border-t text-xs sticky top-0 z-10"
                width="400px"
              >
                NILAI PNBP TCKB
              </th>
            </tr>
            <tr>
              {/* RENBUT JASA EMBOSSING TNKB  */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* RENBUT JASA EMBOSSING TCKB  */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* EMBOSSING TNKB */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* EMBOSSING TCKB */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-yellow-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-yellow-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* EMBOSSING TNKB (HUTANG) */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* EMBOSSING TCKB (HUTANG) */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-lime-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-lime-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* SISA RENBUT EMBOSSING TNKB */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-emerald-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-emerald-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* PERSEN PENGGUNAAN MATERIAL */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-pink-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-pink-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* SISA RENBUT EMBOSSING TCKB */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-indigo-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-indigo-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>

              {/* NILAI PNBP TNKB */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100 sticky top-[75px] z-10">
                TOTAL
              </th>

              {/* NILAI PNBP TCKB */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-violet-100 sticky top-[75px] z-10">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-violet-100 sticky top-[75px] z-10">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-violet-100 sticky top-[75px] z-10">
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((val, index) => (
              <>
                <tr
                  key={val.id || index}
                  className="border-b border-dashed text-xs"
                >
                  <td className="p-2 text-center text-sm sticky left-0 top-0 bg-white z-20">
                    {index + 1}
                  </td>
                  <td className="px-2 py-2 text-gray-900 sticky left-[50px] top-0 bg-white z-10">
                    <strong>POLDA {val.namaPolda}</strong>
                  </td>
                  {/* RENBUT JASA EMBOSSING TNKB  */}
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.totRenbutR2)}
                  </td>
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.totRenbutR4)}
                  </td>

                  {/* RENBUT JASA EMBOSSING TCKB  */}
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>

                  {/* EMBOSSING TNKB */}
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Konfirm)}
                  </td>
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Konfirm)}
                  </td>

                  {/* EMBOSSING TCKB */}
                  <td className="p-2 text-center bg-yellow-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>
                  <td className="p-2 text-center bg-yellow-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>

                  {/* EMBOSSING TNKB (HUTANG) */}
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Hutang)}
                  </td>
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Hutang)}
                  </td>

                  {/* EMBOSSING TCKB (HUTANG) */}
                  <td className="p-2 text-center bg-lime-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>
                  <td className="p-2 text-center bg-lime-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>

                  {/* SISA RENBUT EMBOSSING TNKB */}
                  <td className="p-2 text-center bg-emerald-50">
                    {new Intl.NumberFormat("id-ID").format(val.sisaRenbutR2)}
                  </td>
                  <td className="p-2 text-center bg-emerald-50">
                    {new Intl.NumberFormat("id-ID").format(val.sisaRenbutR4)}
                  </td>

                  {/* PERSEN PENGGUNAAN MATERIAL */}
                  <td>
                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                      {val.persenRenbutR2g >= 50 ? (
                        <div
                          className="bg-rose-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                          style={{ width: `${val.persenRenbutR2g}%` }}
                        >
                          {val.persenRenbutR2}
                        </div>
                      ) : (
                        <div
                          className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                          style={{ width: `${val.persenRenbutR2g}%` }}
                        >
                          {val.persenRenbutR2}
                        </div>
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                      {val.persenRenbutR4g >= 50 ? (
                        <div
                          className="bg-rose-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                          style={{ width: `${val.persenRenbutR4g}%` }}
                        >
                          {val.persenRenbutR4}
                        </div>
                      ) : (
                        <div
                          className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                          style={{ width: `${val.persenRenbutR4g}%` }}
                        >
                          {val.persenRenbutR4}
                        </div>
                      )}
                    </div>
                  </td>

                  {/* SISA RENBUT EMBOSSING TCKB */}
                  <td className="p-2 text-center bg-indigo-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>
                  <td className="p-2 text-center bg-indigo-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>

                  {/* NILAI PNBP TNKB */}
                  <td className="p-2 text-right bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.pnbpR2)}
                  </td>
                  <td className="p-2 text-right bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.pnbpR4)}
                  </td>
                  <td className="p-2 text-right bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.jmlPnbp)}
                  </td>

                  {/* NILAI PNBP TCKB */}
                  <td className="p-2 text-right bg-violet-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>
                  <td className="p-2 text-right bg-violet-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>
                  <td className="p-2 text-right bg-violet-50">
                    {new Intl.NumberFormat("id-ID").format(0)}
                  </td>
                </tr>
              </>
            ))}
            <tr>
              <th
                colSpan="2"
                className="px-2 py-3 text-center text-gray-600 bg-gray-100 border-t border-b sticky left-0 top-0"
              >
                TOTAL
              </th>

              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Renbut)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Renbut)}
              </th>

              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>

              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Konfirm)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Konfirm)}
              </th>

              <th className="px-2 py-3 text-center border-b border-t bg-yellow-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-yellow-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>

              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Hutang)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Hutang)}
              </th>

              <th className="px-2 py-3 text-center border-b border-t bg-lime-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-lime-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>

              <th className="px-2 py-3 text-center border-b border-t bg-emerald-50">
                {new Intl.NumberFormat("id-ID").format(data.totalSisaRenbutR2)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-emerald-50">
                {new Intl.NumberFormat("id-ID").format(data.totalSisaRenbutR4)}
              </th>

              <th className="px-2 py-3 text-center border-b border-t"></th>
              <th className="px-2 py-3 text-center border-b border-t"></th>

              <th className="px-2 py-3 text-center border-b border-t bg-indigo-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-indigo-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>

              <th className="px-2 py-3 text-right border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalPnbpR2)}
              </th>
              <th className="px-2 py-3 text-right border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalPnbpR4)}
              </th>
              <th className="px-2 py-3 text-right border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalPnbp)}
              </th>

              <th className="px-2 py-3 text-right border-b border-t bg-violet-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>
              <th className="px-2 py-3 text-right border-b border-t bg-violet-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>
              <th className="px-2 py-3 text-right border-b border-t bg-violet-50">
                {new Intl.NumberFormat("id-ID").format(0)}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableDashboardRenbut;
